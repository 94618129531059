import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Column from "../../../components/column"
import Columns from "../../../components/columns"
import Paragraph from "../../../components/paragraph"
import Image from "../../../components/image"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheEssenTrinken = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheEssenTrinkenQuery {
      laTorre: file(
        relativePath: { eq: "leichte-sprache/essen-la-torre.jpg" }
      ) {
        ...mediumImage
      }
      roecker: file(relativePath: { eq: "leichte-sprache/essen-roecker.jpg" }) {
        ...mediumImage
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="Essen und Trinken in der Nähe"
        description="Im Museum dürfen Sie nicht essen und trinken. In der Nähe vom Hölderlin∙turm gibt es aber Möglichkeiten zum Essen und Trinken."
      />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Leichte Sprache",
              link: "/leichte-sprache",
            },
            {
              title: "Besuch",
              link: "/leichte-sprache/besuch",
            },
            {
              title: "Essen und Trinken",
              link: "/leichte-sprache/besuch/essen-und-trinken",
            },
          ]}
        />
        <PageTitle>Essen und Trinken in der Nähe</PageTitle>
        <Paragraph>
          Im Museum dürfen Sie nicht essen und trinken. <br />
          In der Nähe vom Hölderlin∙turm gibt es aber Möglichkeiten zum Essen
          und Trinken.
        </Paragraph>

        <Paragraph>
          Es gibt eine Bäckerei. <br /> Die Bäckerei heißt ›Padeffke‹. <br />
        </Paragraph>

        <Columns collapse={[true, false]} space={12}>
          <Column width={["full", "1/3"]}>
            <Image
              image={data.roecker.childImageSharp.gatsbyImageData}
              attribution="Hofkonditorei Röcker"
              alt="Die Kuchen-Auswahl in der Konditorei Röcker"
            />
          </Column>
          <Column>
            <Paragraph>
              Und es gibt ein Café mit Kuchen. <br />
              Das Café heißt ›Röcker‹.
            </Paragraph>
          </Column>
        </Columns>

        <Paragraph>
          In der Neckargasse gibt es 2 Eis∙dielen. <br />
          Eine Eis∙diele heißt ›Porto Pino‹. <br />
          Die andere Eis∙diele heißt ›La Dolce Vita‹.
        </Paragraph>

        <Columns collapse={[true, false]} space={12}>
          <Column width={["full", "1/3"]}>
            <Image
              image={data.laTorre.childImageSharp.gatsbyImageData}
              attribution="La Torre Pizzeria"
              alt="Die Terasse vom Restaurant La Torre Pizzeria"
            />
          </Column>
          <Column>
            <Paragraph>
              In der Neckargasse gibt es ein italienisches Restaurant. <br />
              Es heißt ›La Torre Pizzeria Restaurante‹.
            </Paragraph>
          </Column>
        </Columns>

        <Paragraph>
          In der Bursagasse gibt es noch 2 italienische Restaurants. <br />
          Sie heißen ›Al Dente‹ und ›Trattoria La Cantinella‹.
        </Paragraph>

        <Paragraph>
          Und es gibt ein Restaurant mit asiatischem Essen. <br />
          Es heißt ›San Bao‹.
        </Paragraph>

        <Paragraph>
          Der Garten gehört auch zum Museum. <br />
          Dort dürfen Sie auch nicht essen und trinken. <br />
          Und Sie dürfen keinen Müll dort liegen lassen.
        </Paragraph>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheEssenTrinken
